import React from "react"
import Layout from "../components/layout"
import SearchBar from "../components/searchbar"
import SEO from "../components/seo"

import "../components/style.css"

const contact = () => (
  <>
    <Layout>
      <SEO title="Global Facebook search" />
      <div className="container">
        <div className="centered">
          <div className="style1">
            <h1 className="style1"> Search the Entire World </h1>
            <div> Items for Sale </div>
          </div>
          <div className="style2">Remove the 100-mile boundary</div>
          <input type="submit" value="Learn More" className="button1" />
        </div>
      </div>

      <h1>Contact Page</h1>

      <div className="countrybar">
        <div className="about">
          <h3>Created: </h3>
          <p>Nathan Johnson 10/2/2019 </p>
          <p> Milwaukee, Wisconsin</p>
          <h3>Devloped with: </h3>
          <p> React + Gatsby </p>

          <p></p>
          <div>
            <h3>Recommendations:</h3>

            <div style={{ fontFamily: "Arial, sans-serif" }}>
              <p style={{ margin: 0, padding: 0 }}>
                <a
                  href="https://njtautomation.com"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  NJT Automation
                </a>
              </p>
              <div style={{ margin: "10px 0" }}>
                <p style={{ margin: 0, padding: 0 }}>
                  The best place to{" "}
                  <a
                    href="https://shop.njtautomation.com"
                    style={{ textDecoration: "none", color: "#0073e6" }}
                  >
                    buy
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://njtautomation.com/Sell-Components/"
                    style={{ textDecoration: "none", color: "#0073e6" }}
                  >
                    sell
                  </a>{" "}
                  hard-to-find industrial electronics
                </p>
              </div>
              They also provide:
              <p style={{ margin: 0, padding: 0 }}>
                <a
                  href="https://njtautomation.com/milwaukee/"
                  style={{ textDecoration: "none", color: "#0073e6" }}
                >
                  Electronics Recycling in Milwaukee and surrounding areas
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <SearchBar />
    </Layout>
  </>
)

export default contact
